*{
    font-family: 'Antonio', sans-serif;
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style-type: none;
    overflow-x: hidden;
    box-sizing: border-box;

}
a:link, a:visited{
    color: #DFE0DF;
}
body{
    background: #DFE0DF;
}

body::-webkit-scrollbar {
    display: none;
  }
/* NavBar */

nav{
    background: #E28340;
}
.nav-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.img-container{
    width: 130px;
    min-width: 91px;
    height: auto;
}
.img-container img{
    width: 100%;
}
.nav-item-container{
    display: flex;
}

.nav-item {
    padding: 2em;
    font-size: 14px;
    color: #DFE0DF;
}

.item{
    cursor: pointer;
}
.connect-btn{
    font-size: 14px;
    cursor: pointer;
    background: none;
    border: none;
    color: #DFE0DF;
}
.nav-item :hover{
    color:#cecece;
}
.social-container{
    display: flex;
}
.social-link a{
    cursor: pointer;
}
.social-link a img{
    padding: .3em;
    width: 30px;
}


/* Header */

header{
    background:#E28340;
    background-image: url("./Images/header-detail.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    height: 88vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    color: #DFE0DF;
}

.quote-container{
    width: 40vw;
    height: 60vh;
    margin: 0 160px;
}

.quote{
    font-weight: 400;
    font-size: 50px;
    width: 260px;
}
.about-summary{
    font-family: 'Poppins' , sans-serif;
    font-weight: 600;
    margin: 1em 0;
    font-weight: 100;
}

.resume-btn{
    background: #2B2B2B;
    color: #DFE0DF;
    margin-top: 2em;
    padding: 10px 40px;
    border: 0;
    border-radius: 30px;
    font-family: 'Poppins' , sans-serif;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
}
.resume-btn:hover{
    background: #000000;
}

/* Projects */

.projects-title-container{
    margin: 0 auto;
    width: 70%;
    position: relative;
    top: -50px;
    padding: 30px;
    background:#DFE0DF;
    letter-spacing: 1px;

}
.title{
    background-image: linear-gradient(to right top, #E28340, #e9bba8);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    text-align: center;

    font-weight: 700;
    font-size: 50px;
}

.tab-btn-container{
    display: flex;
    gap: 1rem;
    margin: 1rem;
    padding: 1rem;
}

.tab-btn{
    background: #2B2B2B;
    color: #DFE0DF;
    margin-top: 2em;
    padding: 10px 20px;
    border: 0;
    border-radius: 30px;
    font-family: 'Poppins' , sans-serif;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
}

.tab-btn.active{
    color: #2B2B2B;
    background: #DFE0DF;
    border: 1px solid #2B2B2B;
    scale: 105%;
    transition: ease-out 0.2s;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1),0px 5px 15px -3px rgba(0,0,0,0.1);
}

.projects{
    background: #DFE0DF;
    min-height: 100vh;
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('./Images/ProjectDetail1.svg'), url('./Images/ProjectDetail2.svg'), url('./Images/ProjectDetail3.svg');
    background-size: 120px, 900px, 500px;
    background-position:
        right top,
        left -200px top 500px,
        right bottom;
    background-repeat: no-repeat;
}

.project-container{
   width:80%;
   max-width: 2000px;
   max-height: 800px;
   margin: 0 auto;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-bottom: 100px;
}


.mockup-card{
    width: auto;
    max-height: 700px;
    max-width: 1000px;
    overflow: hidden;
}

.mockup-card img{
    width:100%;
    height: 100%;
}

.video-card.mobile video{
    height: 100%;
}
.video-card{
    height: 600px;
    max-width: 1000px
}

.cta-card{
    background: #DFE0DF;
    display:flex;
    max-width: 320px;
    max-height: 320px;
    position:relative;
    left: -2em;
    flex-direction: column;
    align-items: center;
    padding: 1em;
    box-shadow: 0 0 20px 10px rgba(0,0,0,.1);

}
.project-description::-webkit-scrollbar {
    display: none;
  }

.cta-card.animation{
    width: 400px;
}

.cta-card.mobile{
    max-width: 400px;
}



.project-detail-container h2{
    color: #E28340;
    font-weight: 300;
    font-size: 30px;
    letter-spacing: .5px;
}

.project-tools{
    font-weight: 100;
    color: #2B2B2B;
    font-size: 12px;
    letter-spacing: 1px;

}

.project-description{
    margin-top: .5em;
    font-family: 'Poppins' , sans-serif;
    font-weight: 400;
    line-height: 20px;
    font-size: 13px;
}

.project-links-container{
    display:flex;
    margin-top: 1em;

}
.link-container p{
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
    color:#2B2B2B;
}

.link-container a img{
    width: 30px;
    height: 30px;
    margin-bottom: 3px;
}

.vl{
    border-left: 3px solid #2B2B2B;
    height: 50px;
    margin: 0 20px;
    position: relative;
    border-radius: 3px;

}
/* Tools */
.tools-title-container{
    margin: 0 auto;
    width: 70%;
    position: relative;
    top: 50px;
    padding: 30px;
    background:#DFE0DF;
    letter-spacing: 1px;
}
.tools{
    min-height: 700px;
    background: #E28340;
    background-image: url('./Images/tool-detail-right.svg'), url('./Images/tool-detail-left.svg');
    background-position: 
        top -50px left -200px,
        top 200px right -200px ;
    background-repeat: no-repeat;
    background-size: 400px;
}

.tools-container{
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 20px 0;
}

.tool-item{
    height: 300px;
    width:5%;
    margin:0 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #DFE0DF;
    border-radius: 50px;
    transition: width 0.2s ease-in;
}

.tools-container :hover{
    box-shadow: 0 0 0 2px #DFE0DF;

}

.tool-item img{
    width: 110px;
    height: 110px;
}

.tool-item h4{
    margin-top: 30px;
    text-transform: uppercase;
    opacity: 0;
    transition: opacity 0.5s ease-in;
}

.tool-item.active{
    width: 20%;
    box-shadow: none;
}
.tool-item.active h4{
    opacity: 1;
    
}



/* Footer */
footer{
    display: flex;
    background: #E28340;
    background-image: url('./Images/FooterDetail.svg');
    background-size: 1300px;
    height: 110px;
    background-repeat: no-repeat;
    background-position: right top;
    align-items: center;
    justify-content: space-around;
}


/* About */

.about-title-container{
    margin: 0 auto;
    width: 70%;
    position: relative;
    top: -50px;
    padding: 30px;
    background:#DFE0DF;
    letter-spacing: 1px;
}

.about{
    position: relative;
    height: 1400px;
    overflow: hidden;
}
.about-detail{
    position: absolute;
    bottom: -1px;
    right: -20px;
    width: 102%;
}
.about-container{
    display: flex;
    margin-top: 100px;
    height: 1000px;
}

.illustration-container img{
    width: 100%;
}

.illustration-container{
    width: 50%;
    min-width: 260px;
}

.about-detail-container{
    width: 50%;
    margin: 350px 20px 0 0px;
}
.about-detail-container h2{
    text-transform: uppercase;
    font-family: 'Poppins' , sans-serif;
    font-weight: 900;
    font-size: 60px;
    color: #2B2B2B;
}

.about-detail-container hr{
    border: 3px solid #DFCDBF;
    width: 110px;
    position: relative;
    top: -12px;
}
.about-detail-container p{
    max-width: 500px;
    color: #E28340;
    line-height: 40px;
    font-size: 23px;
    font-weight: 100;

}

/*  RESPONSIVE */



@media screen and (max-width: 1080px){

     /* Header */
     header{
        background-position: bottom;
    }
    /* projects */
    
    .project-detail-container h2{
        font-size: 25px;
    }

    .project-tools{
        font-size: 10px;
    }

    .project-description{
        font-size: 10px;
        line-height: 15px;
    }

    .link-container p{
        font-size: 10px;
    }

    /* About */
    .about-detail{
        bottom: -1px;
        right: -20px;
        width: 105%;
    }
    
    .about-container{
        height: 800px;
    }
    
    .illustration-container{
        width: 500px;
    }
    
    .about-detail-container{
        margin: 300px 20px 0 -80px;
    }
    .about-detail-container h2{
        font-size: 48px;
    }
    
    .about-detail-container hr{
        top: -10px;
    }
}

@media screen and (max-width: 970px){

    /* projects */
    .projects{
        background-position:
        right top, 
        left -300px top 400px, 
        right bottom;
    }
    .project-detail-container h2{
        font-size: 20px;
    }

    .project-tools{
        font-size: 10px;
    }
    .project-description{
        font-size: 10px;
        line-height: 11px;
    }
    .cta-card{
        height: 210px;
        max-width: 280px;
    }
    .cta-card.animation{
        width: 480px;
        height: auto;
    }
    .cta-details{
        height: auto;
        width: auto;
    }
}

@media screen and (max-width: 790px){
    /* NavBar */
    nav.container{
        justify-content: center;
        margin: 0 20px;
    }
    .img-container{
        padding-left: 20px;
    }
    .social-container{
        display: none;

    }

    /* Header */

    header{
        background-size: cover;
        background-position: -150px 0;
    }
    
    .quote-container{
        width: 70vw;
        margin: 0 80px;
    }
    
    /* projects */

    .projects{
        background-position:
        right top, 
        left -600px top 400px, 
        right bottom;
    }
    .title{
        font-size: 35px;
    }

    .project-container{
        flex-direction: column;
        width: 370px;
    }

    .project-images-container{
        position: relative;
        left: -10px;
    }
    .cta-card{
        left: -3px;
        top: -20px;
        height: auto;
        max-width: 330px;
        box-shadow: 0 5px 5px rgba(0,0,0,.1);
        
    }
    .project-detail-container h2{
        font-size: 18px;
    }
    .project-tools{
        font-size: 9px;
    }
    .project-description{
        margin-top: 1em;
        font-size: 10px;
        line-height: 13px;
        overflow: hidden;
    }

    .project-links-container{
        margin-top: .5em;

    }
    .link-container p{
        font-size: 8px;
    }

    .link-container a img{
        width: 20px;
        height: 20px;
        margin-bottom: 1px;
    }

    .vl{
        border-left: 1px solid #2B2B2B;
        height: 33px;
        margin: 0 15px;
    }

    /* About */
    
    .about{
        height: 900px;
    }
    .about-container{
        margin-top: 30px;
        height: 700px;
    }

    .illustration-container{
        width: 500px;
    }
    
    .about-detail-container{   
        margin: 250px 20px 0 -70px;
    }
    .about-detail-container h2{
        font-size: 34px;
    }
    
    .about-detail-container hr{
        
        width: 80px;
        position: relative;
        top: -5px;
    }
    .about-detail-container p{
        margin-top: 5px;
        line-height: 33px;
        font-size: 19px;
        min-width: 170px;
    }
}


@media screen and (max-width: 620px){
    /* header */
    header{
        background-position: -500px 0;
    }
    .quote-container{
        width: 80vw;
        margin: 0 40px;
    }

    /* tools */
    
    .tools-container{
        margin-top: 100px ;
        flex-direction:column;
    }
    
    .tool-item{
        height: 70px;
        width: 300px;
        margin:5px 0;
        transition: height 0.5s ease-in;
    }
    
    .tool-item img{
        width: 110px;
        height: 110px;
    }
    .img-card{
        overflow: hidden;
        position: relative;
        
        
    }
    .tool-item h4{
        margin-top: 0;
        height: 0;
    }

    .tool-item.active{
        height: 300px;
        width: 300px;
    }
    .tool-item.active h4{
        opacity: 1;
        margin-top: 30px;
        height: auto;
        transition: height 0.5s ease-in;
        
    }

    /* About */

    .about{
        height: 900px;
    }
    .about-container{
        margin-top: 30px;
        height: 700px;
    }

    .illustration-container{
        width: 500px;
    }

    .about-detail-container{
        margin: 150px 20px 0 -50px;
    }
    .about-detail-container h2{
        font-size: 34px;
    }

    .illustration-container{
        width: 400px;
    }
    
    .about-detail-container hr{
        
        width: 80px;
        position: relative;
        top: -5px;
    }
    .about-detail-container p{
        margin-top: 5px;
        line-height: 30px;
        font-size: 20px;
        min-width: 170px;
    }
}

@media screen and (max-width: 530px){
    /* About */
    
    .about{
        height: 600px;
    }
    .illustration-container{
        width: 270px;
    }
    .about-detail-container{
        margin: 120px 20px 0 -65px;
    }
    .about-detail-container h2{
        font-size: 30px;
    }
    
    .about-detail-container hr{
        border: 0;
    }
    .about-detail-container p{
        margin-top: 5px;
        line-height: 22px;
        font-size: 15px;
        min-width: 170px;
    }
}

@media screen and (max-width: 441px){
    /* About */
    
    .about{
        height: 600px;
    }

    .about-detail{
        position: absolute;
        bottom: -1px;
        right: -20px;
        width: 107%;
    }
    .about-container{
        margin-top: 30px;
        height: 500px;
    }
    .about-detail-container{
        margin: 90px 20px 0 -75px;
    }
    .about-detail-container h2{
        font-size: 30px;
    }
    
    .about-detail-container hr{
        border: 0;
    }
    .about-detail-container p{
        margin-top: 5px;
        line-height: 18px;
        font-size: 13px;
        min-width: 170px;
    }
}




